import * as feather from 'feather-icons/dist/feather';

feather.replace();

const setTheme = (mode) => {
  localStorage.setItem('colorTheme', mode);

  const body = document.querySelector('body');

  if (mode === 'dark') {
    document.getElementById('darkModeStyle').disabled = false;
    body.classList.add('dark');
    body.classList.remove('light');

    document.getElementById('sunIcon').style.display = 'inline-block';
    document.getElementById('moonIcon').style.display = 'none';
  } else if (mode === 'light') {
    document.getElementById('darkModeStyle').disabled = true;
    body.classList.add('light');
    body.classList.remove('dark');

    document.getElementById('sunIcon').style.display = 'none';
    document.getElementById('moonIcon').style.display = 'inline-block';
  }
};

const getSavedTheme = () => {
  const currentTheme = localStorage.getItem('colorTheme');
  if (!currentTheme) {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      return 'dark';
    } else {
      return 'light';
    }
  }

  return currentTheme;
};

setTheme(getSavedTheme());

const toggleTheme = () => {
  if (localStorage.getItem('colorTheme') === 'dark') {
    setTheme('light');
  } else if (localStorage.getItem('colorTheme') === 'light') {
    setTheme('dark');
  }
};

document
  .getElementById('colourModeToggle')
  .addEventListener('click', toggleTheme);
